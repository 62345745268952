let user = JSON.parse(localStorage.getItem("user"))
  ? JSON.parse(localStorage.getItem("user"))
  : {};
const initialState = user;

const reducer = (state = initialState, action) => {
  if (action.type === "LOGIN") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export default reducer;
